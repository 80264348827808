import { BasilSearchSolid } from '@/assets/icons/comm/BasilSearchSolid';
import BaseInput from '@/components/base/baseInput';
import BaseTopNav from '@/components/base/baseTopNav';
import createVerifiedModel from '@/pages/mine/verified/useStateHooks';
import useUrlState from '@ahooksjs/use-url-state';
import { history, useModel } from '@umijs/max';
import { useMount } from 'ahooks';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {ChoiceOne} from "@/assets/icons/comm/ChoiceOne"
import { set } from 'lodash';
export default () => {
  const [value, changeValue] = useState('');
  const { t } = useTranslation();
  const [urlState] = useUrlState<any>({});
  const { targetUrl } = urlState || {};
  const { areaCode, setCurrentAreaCode } = useModel('areaCode');
  const model = createVerifiedModel();

  const {  getAreaCodeReq, setForm ,form,setNationality,nationality} = model;
  // 数据 组装 将完全相等的数据放在前面 然后再将包含的放在后面
  const filterData = useMemo(() => {
    const trimmedValue = value.trim();
    if (trimmedValue) {
      const exactMatches = (areaCode as any)?.filter?.((item: any) => {
        return (
          item.name.toLowerCase() === trimmedValue.toLowerCase() ||
          item.alias === trimmedValue
        );
      });
      const partialMatches = (areaCode as any)?.filter?.((item: any) => {
        return (
          (item.name.toLowerCase().includes(trimmedValue.toLowerCase()) ||
            item.alias.includes(trimmedValue)) &&
          !exactMatches.some((match: any) => match.id === item.id)
        );
      });
      return [...(exactMatches ?? []), ...(partialMatches ?? [])];
    } else {
      return areaCode;
    }
  }, [value]);

  useMount(() => {
    getAreaCodeReq();
  });

  const renderData = useMemo(() => {
    if (filterData && filterData?.length) {
      return filterData;
    }
    return areaCode;
  }, [areaCode, filterData]);

  return (
    <div className="sm:mt-20">
      <BaseTopNav title={t('选择国家')} />

      <div
        className="bg-background  flex items-center mx-3"
        style={{ position: 'sticky', top: '0' }}
      >
        <BaseInput
          className="bg-backgroundAuxiliaryColor  overflow-hidden mt-2 mb-3 h-[40px]"
          value={value}
          startContent={
            <div>
              <BasilSearchSolid />
            </div>
          }
          onChange={(e) => changeValue(e.target.value)}
          placeholder={t('搜索')}
          classNames={{
            base: 'rounded-md overflow-hidden h-[40px]',
            inputWrapper:
              'h-full border-none rounded-[30px] rounded-md group-data-[focus=true]:border-backgroundAuxiliaryColor',
          }}
        ></BaseInput>
        <span
          className="ml-2 text-primary flex-shrink-0"
          onClick={() => {
            history.back();
          }}
        >
          {t('取消')}
        </span>
      </div>
      <div className="px-4">
        {
          // (areaCode as any)
          //   ?.filter((item: any) => {
          //     if (value.trim()) {
          //       return (
          //         item.name.toLowerCase().includes(value.toLowerCase()) ||
          //         item.areaCode.includes(value)
          //       );
          //     } else {
          //       return true;
          //     }
          //   })
          renderData?.map((item: any) => {
            return (
              <div
                key={item.id}
                className="flex mt-4 justify-between mb-8 cursor-pointer"
                onClick={() => {
                  console.log( item.alias)
                  // setCurrentAreaCode(item);
                  setForm((prev) => ({ ...prev, nationality: item.alias }));
                  setNationality( item.alias)
                  // history.replace(targetUrl + location.search);、
                  history.back();
                }}
              >
                <div className="flex items-center">
                  {/* <MaterialSymbolsLanguage width="20px" height="20px" /> */}
                  <img
                    className="w-[20px] h-[20px] rounded-full object-cover"
                    src={item?.iconUrl}
                    alt=""
                  />
                  {/* <span className="ml-2 text-base">{item?.name}</span> */}
                <span className=" ml-2 text-base ">{item.alias}</span>

                </div>
                {/* <span className="text-auxiliaryTextColor">{item.alias}</span> */}
                {item.alias===nationality&&<ChoiceOne/>}
              </div>
            );
          })
        }
      </div>
    </div>
  );
};
