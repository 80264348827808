import type { SVGProps } from 'react';

export function ChoiceOne(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.6954 4.16903L6.52236 13.4528L6.25376 13.7058L5.98516 13.4528L0.304639 7.70359L2.15678 5.82879L6.25332 9.97535L13.8432 2.29395L15.6954 4.16903Z"
        fill="hsl(var(--nextui-primary)"
      />
    </svg>
  );
}
